import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import getPhotoToken from 'utils/get-photo-token';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';
import coverImgEmpty from 'images/placeholders/cover-business-profile_empty.svg';
import logoImgEmpty from 'images/placeholders/business.svg';
import './styles.less';

import DABIE_create from 'images/DABIE_create_business_profile.svg';
import { Button, TextArea } from 'components/atoms/';

import { CREATE_BUSINESS_WATCHER } from 'store/create-business/constants';
import { GET_COUNTRIES_WATCHER } from 'store/countries/constants';
import { GET_COUNTRY_STATE_WATCHER } from 'store/country-state/constants';
import { GET_LIST_BUSINESS_WATCHER } from 'store/list-business/constants';
import { GET_INDUSTRIES_WATCHER } from 'store/list-industries/constants';
import { GET_INDUSTRY_CATEGORIES_WATCHER } from 'store/list-industry-categories/constants';
import { actionWatcher } from 'shared/actions';

const BusinessCreate = ({
	createBusiness,
	getCountries,
	countries,
	getCountryState,
	countryState,
	getListBusiness,
	listBusiness,
	getListIndustries,
	listIndustries,
	getListIndustryCategories,
	listIndustryCategories,
	createBusinessProcess
}) => {
	let inputRefLogo, inputRefCover;
	
	const [businessProfilePhoto, setBusinessProfilePhoto] = useState('');
	const [businessCoverPhoto, setBusinessCoverPhoto] = useState('');
	const { showSuccessToast, showFailureToast } = usePreDefinedToasts();
	const [values, setValues] = useState({ companyName: '', categories: [] });
	const handleImageFileChange = async e => {
		let inputID = e.target.id;
		if (e.target.files.length === 0) return;
		let objectURL = URL.createObjectURL(e.target.files[0]);
		let imageFile = e.target.files[0];
		let messageUpload = 'Loading your business profile picture',
			messageConfirm = 'Profile picture loaded';
		try {
			let field = 'profilePhoto';

			if (inputID === 'businessProfileCover') {
				setBusinessCoverPhoto(objectURL);
				messageUpload = 'Loading your business cover picture';
				messageConfirm = 'Cover picture loaded';
				field = 'coverPhoto';
			} else {
				setBusinessProfilePhoto(objectURL);
			}
			showSuccessToast(messageUpload);
			let token = await getPhotoToken(`files/upload-request/business`, imageFile);
			if (inputID === 'businessProfileCover') {
				field = 'coverPhoto';
			}
			setValues({ ...values, [field]: token });

			showSuccessToast(messageConfirm);
		} catch (err) {
			showFailureToast('An error has ocurred when we tried to upload your picture');
		}
	};
	useEffect(() => {
		getCountries();
		getListBusiness();
		getListIndustries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(()=>{
		if(createBusinessProcess.error){
			showFailureToast('Complete all the information required to create your business profile.');
		}
		// eslint-disable-next-line
	}, [createBusinessProcess.error])
	return (
		<>
			<div className="container">
				<div className="create-business__title">
					<p>
						CREATING YOUR COMPANY PROFILE: Great, now you can create your Company Profile to show to the
						worldwide B2B market your business capabilities, products, technical specifications, trade
						capabilities, contact information and many useful details to start doing global business
					</p>
				</div>
				<div className="create-business-cover">
					<div className="create-business-cover__photo">
						<img
							src={businessCoverPhoto || coverImgEmpty}
							className="create-business-cover__photo-img"
							alt="create business cover"
						/>
					</div>
					<div className="create-business-cover__actions">
						<div></div>
						<Button
							block="true"
							onClick={() => {
								inputRefCover.click();
							}}
						>
							Upload your cover photo
						</Button>
						<input
							type="file"
							accept="image/*"
							id="businessProfileCover"
							ref={input => (inputRefCover = input)}
							onChange={handleImageFileChange}
							style={{ display: 'none' }}
						/>
						<p>
							Maximun 500KB <br />
							Recomendable proportion 200 x 800
						</p>
					</div>
				</div>
				<div className="create-business-main">
					<div className="create-business-main__logo-section">
						<img
							src={businessProfilePhoto || logoImgEmpty}
							className="create-business-main__img"
							alt="comment"
						/>
						<div className="create-business-main__actions">
							<Button
								onClick={() => {
									inputRefLogo.click();
								}}
							>
								Upload your logo
							</Button>
							<input
								type="file"
								accept="image/*"
								id="businessProfileLogo"
								ref={input => (inputRefLogo = input)}
								onChange={handleImageFileChange}
								style={{ display: 'none' }}
							/>
							<p>Maximun 500KB</p>
						</div>
					</div>
					<div className="create-business-main__info">
						<div className="create-business-main__info--left">
							<Form>
								<Form.Field>
									<label>Company Name</label>
									<Input
										value={values.companyName}
										onChange={event => setValues({ ...values, companyName: event.target.value })}
									/>
								</Form.Field>
								<Form.Field>
									<label>Main Industry</label>
									<Select
										placeholder="Select Industry"
										value={values.industry}
										options={listIndustries}
										onChange={(_, data) => {
											getListIndustryCategories(data.value);
											setValues({ ...values, industry: data.value });
										}}
									/>
								</Form.Field>
								<Form.Field>
									<label>Main Categories</label>
									<Dropdown
										multiple
										selection
										fluid
										value={values.categories}
										placeholder="Select multiple categories"
										options={listIndustryCategories}
										onChange={(_, data) => {
											setValues({ ...values, categories: data.value });
										}}
									/>
								</Form.Field>
							</Form>
						</div>
						<div className="create-business-main__info--right">
							<Form>
								<Form.Field>
									<label>Business Type</label>
									<Select
										placeholder="Manufacturer"
										value={values.businessType}
										options={listBusiness}
										onChange={(_, data) => {
											setValues({ ...values, businessType: data.value });
										}}
									/>
								</Form.Field>
								<Form.Field>
									<label>Country</label>
									<Select
										placeholder="Select industry"
										value={values.country}
										options={countries}
										onChange={(_, data) => {
											getCountryState(data.value);
											setValues({ ...values, country: data.value });
										}}
									/>
								</Form.Field>
								<Form.Field>
									<label>State</label>
									<Select
										placeholder="Choose the state/region"
										value={values.state}
										options={countryState}
										search
										onChange={(_, data) => {
											setValues({ ...values, state: data.value });
										}}
									/>
								</Form.Field>
							</Form>
						</div>
					</div>
				</div>
				<div className="create-business-footer">
					<div className="create-business-footer__description">
						<div className="create-business-footer__input">
							<p>Company short description</p>
							<TextArea
								placeholder="Tell us more about your business"
								rows={8}
								value={values.shortDescription}
								onChange={event => setValues({ ...values, shortDescription: event.target.value })}
							/>
						</div>
						<div className="create-business-footer__buttons">
							<Button className="wbn-button wbn-button-cancel">Cancel</Button>
							<Button
								isLoading={createBusinessProcess.loading}
								disabled={createBusinessProcess.loading}
								onClick={() => {
									const { industry, ...data } = {
										...values,
										categories: map(values.categories, item => `${values.industry}.${item}`)
									};
									try{
										createBusiness({ data });
									}catch(error){
										console.log(error);
									}
									
								}}
							>
								Create your Business Profile
							</Button>
						</div>
					</div>
					<div className="create-business-footer__advice">
						<img
							src={DABIE_create}
							className="create-business-footer__advice-photo"
							alt="create business footer"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = ({
	listIndustries,
	countries,
	countryState,
	listBusiness,
	listIndustryCategories,
	createBusiness
}) => {
	return {
		countries: countries.countries,
		countryState: countryState.data,
		listBusiness: listBusiness.data,
		listIndustries: listIndustries.data,
		listIndustryCategories: listIndustryCategories.data,
		createBusinessProcess: createBusiness
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createBusiness: params => dispatch(actionWatcher(CREATE_BUSINESS_WATCHER, params)),
		getCountries: () => dispatch(actionWatcher(GET_COUNTRIES_WATCHER)),
		getCountryState: params => dispatch(actionWatcher(GET_COUNTRY_STATE_WATCHER, params)),
		getListBusiness: () => dispatch(actionWatcher(GET_LIST_BUSINESS_WATCHER)),
		getListIndustries: () => dispatch(actionWatcher(GET_INDUSTRIES_WATCHER)),
		getListIndustryCategories: params => dispatch(actionWatcher(GET_INDUSTRY_CATEGORIES_WATCHER, params))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCreate);
