import React, { useEffect } from 'react';
import qs from 'qs';
import apiClient from 'api-client';
const SetIDComponent = () => {
	useEffect(() => {
		const getSession = async () => {
			let token = qs.parse(window.location.search.slice(1)).token || '';
			let continueURL = qs.parse(window.location.search.slice(1)).continue;
			try {
				let response = await apiClient.post(
					`${process.env.REACT_APP_AUTH_URL}get-session`,
					{
						token: token
					},
					{
						withCredentials: true
					}
				);
				let session = response.data.data;
				localStorage.setItem('token', session.token);
				localStorage.setItem('tokenExpire', session.tokenExpire * 1000);
				localStorage.setItem('refreshToken', session.refreshToken);
				localStorage.setItem('refreshTokenExpire', session.refreshTokenExpire * 1000);
				window.location.href = continueURL?continueURL:`${window.location.origin}`;
			} catch (error) {
				if (error.data && !error.data.success) {
					alert('Error en token');
					window.location.replace("/");
				} else {
					console.log("EEEERORRRR", error);
					console.log('Network error');
				}
			}
		};
		getSession();
	}, []);
	return (
		<div className="mainGrid">
			<div style={{ marginTop: '5em', height: '50vh' }}>
				<h1>Redirecting to your account...</h1>
			</div>
		</div>
	);
};
export default SetIDComponent;
