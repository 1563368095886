import React from 'react';
import productPlaceholder from 'images/placeholders/product.svg';
import businessPlaceholder from 'images/placeholders/business.svg';
import { Button } from 'components/atoms/';
import { Link, useHistory } from 'react-router-dom';
import './style.less';


function generateSeoFriendlyLink(product) {
  let companyName = (product.businessProfile.general.companyName) || '';
  let prefix = companyName + ' ' + product.name + '-';

  return encodeURIComponent(prefix.replace(/ /g, '-')) + product.productID;
}

function ContentCardCommentsProducts({ product }) {
	const history = useHistory();
	return (
		<div className="container-product-card">
			<div className="container-product-card__like-photos">
				<div className="container-product-card__like-photos_photo-container">
					<div className="container-product-card__like-photos_photo-container-img">
						<span>{product.photos?.length + product.mainImage ? 1 : 0} photos</span>
            <Link to={`/business-product/${generateSeoFriendlyLink(product)}`}>
              <img
                src={product.mainImage?.url || productPlaceholder}
                alt={`Product ${product.name} ${product.keywords || ''}`}
              />
            </Link>
					</div>
					<div className="container-product-card__like-photos_photo-container-stock">
						<p>Stock</p>
					</div>
					<div className="container-product-card__like-photos_photo-container-like">
						{/*<Icon name="hand point right" /> <p>0 Likes</p>*/}
					</div>
				</div>
			</div>
			<div className="container-product-card__info">
				<div className="container-product-card__info-title">
          <Link to={`/business-product/${generateSeoFriendlyLink(product)}`}>
						<p>
							{product.name} / {product.businessProfile.general.businessType?.name}
						</p>
					</Link>

					<p>{product.businessProfile.general.companyName}</p>
				</div>
				<div className="container-product-card__info-img">
					<img
						src={product.businessProfile.general.profilePhoto?.url || businessPlaceholder}
						alt={`WBNB2B Business Product ${product.name}`}
					/>
				</div>
				<div className="container-product-card__info-tags">
					<p>
						{product.industry.name} | {product.category.name} | {product.subCategory.name}
					</p>
				</div>
				<div className="container-product-card__info-text">
					<p>{product.description}</p>
				</div>
				<div className="container-product-card__info-list">
					<ul>
						{product.specs ? <li>Technical Specifications</li> : null}
						{Object.entries(product.specs || {}).slice(0,4).map(([key, value]) => (
							<li key={key}>
								{key}: {value}
							</li>
						))}
					</ul>
					<div className="container-product-card__info-list-button">
						<Button
							className="ui button"
							onClick={() => history.push(`/business/${product.businessProfile.businessID}/send-request`)}>
							Send Request
						</Button>
						<Button className="ui button">Save Product</Button>
					</div>
				</div>
			</div>
			<div className="container-product-card__info-actions">
				<Button
					className="ui button"
					onClick={() => history.push(`/business/${product.businessProfile.businessID}/send-request`)}>
					Send Request
				</Button>
				<Button className="ui button">Save Product</Button>
			</div>
		</div>
	);
}

export default ContentCardCommentsProducts;
