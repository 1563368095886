import React, { useState } from 'react';
import { CommentSectionStyle } from './style';
import { TextArea, PostComment } from 'components';
import { postCommentOnEntity } from 'utils/social-api';
import useSWR from 'swr';
import userProfilePlaceholder from 'images/icons/user_profile_placeholder.png';
import sendIcon from 'images/icons/send_2.png';
const createPostComment = async ({
	resourceID,
	commentContent,
	setCommentContent,
	revalidateComments,
	resourceType = 'post'
}) => {
	let content = commentContent.content;
	try {
		setCommentContent({ disabled: true, content: '' });
		let response = await postCommentOnEntity({ content, resourceType, resourceID });
		if (response && response.success) {
			setCommentContent({ disabled: false, content: '' });
			revalidateComments();
		} else {
			setCommentContent({ disabled: false, content: content });
		}
	} catch (error) {
		console.error('Error sending comment', error);
		setCommentContent({ disabled: false, content: content });
	}
};
const CommentSection = ({ user, resourceID, commentsData, revalidateComments, resourceType }) => {
	const [commentContent, setCommentContent] = useState({ disabled: false, content: '' });
	return (
		<CommentSectionStyle>
			<CommentSectionStyle.HeaderContainer>
				<CommentSectionStyle.Header>
					{user ? (
						<>
							<div style={{display: "flex"}}>
								<img src={user.profilePhoto?.url || userProfilePlaceholder} alt="post" />
							</div>
							<CommentSectionStyle.Header.TextArea>
								<TextArea
									rows={1}
									placeholder="Post your comment"
									showCounter={false}
									value={commentContent.content}
									disabled={commentContent.disabled}
									onChange={e => {
										setCommentContent({ disabled: false, content: e.target.value });
									}}
									onKeyPress={e => {
										if (e.key === 'Enter' && !e.shiftKey) {
											e.preventDefault();
											createPostComment({
												resourceID,
												commentContent,
												setCommentContent,
												revalidateComments,
												resourceType
											});
										}
									}}
								/>
							</CommentSectionStyle.Header.TextArea>
							<CommentSectionStyle.Header.IconSend
								onClick={() => {
									if (!commentContent.disabled && commentContent.content) {
										createPostComment({
											resourceID,
											commentContent,
											setCommentContent,
											revalidateComments,
											resourceType
										});
									}
								}}
							>
								<img src={sendIcon} alt="WBN Icon create post - The industrial B2B social network" />
							</CommentSectionStyle.Header.IconSend>
						</>
					) : (
						<div>If you want to comment you must be loged in.</div>
					)}
				</CommentSectionStyle.Header>
			</CommentSectionStyle.HeaderContainer>
			<CommentSectionStyle.Body>
				{commentsData.count ? (
					<>
						{commentsData.comments.map((comment, key) => {
							return <PostComment key={key} user={comment.whoComment} body={comment.content} />;
						})}
					</>
				) : (
					<div>No comments yet</div>
				)}
			</CommentSectionStyle.Body>
		</CommentSectionStyle>
	);
};

const CommentSectionWrapper = ({ resourceID, user, resourceType = 'post' }) => {
	const { data: commentsData, error: commentsError, revalidate: revalidateComments } = useSWR(
		'social/comments/' + resourceID
	);
	if (commentsError) return <div>Failed to load comments</div>;
	if (!commentsData) return <div style={{textAlign: "center"}}>Loading...</div>;
	return (
		<CommentSection
			user={user}
			resourceID={resourceID}
			commentsData={commentsData.data?.data}
			revalidateComments={revalidateComments}
			resourceType={resourceType}
		/>
	);
};

export default CommentSectionWrapper;
