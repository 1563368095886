import styled from 'styled-components';
export const CommentSectionStyle = styled.div`
	border-top-style: solid;
	border-top-color: #e3e3e3;
	padding: 5px;
	margin: 0px 10px;
`;
CommentSectionStyle.HeaderContainer = styled.div``;
CommentSectionStyle.Header = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	> div:first-child img {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
		object-fit: contain;
	}
`;
CommentSectionStyle.Header.TextArea = styled.div`
	width: 75%;
	margin: 0px 8px;
	textarea {
		outline: none;
		background-color: #e3e3e3;
		width: 100%;
		font-size: 12px;
	}
`;
CommentSectionStyle.Header.IconSend = styled.div`
	display: flex;
	border-radius: 50%;
	border: 1px solid #e3e3e3;
	width: 35px;
	height: 35px;
	padding: 2px;
	justify-content: center;
	align-items: center;
	> img {
		width: 26px;
		height: 26px;
	}
`;
CommentSectionStyle.Body = styled.div``;
