import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import logoWbn from 'domain_brand';
import SubCategories from '../sub-categories';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './styles.less';
import data from './industries-data';

import { GET_INDUSTRY_FULL_TREE_WATCHER } from 'store/list-industry-full-tree/constants';
import { actionWatcher } from 'shared/actions';

const SidebarLeft = ({ getIndustriesFullTree, industriesCategories }) => {
	useEffect(() => {
		getIndustriesFullTree();
	}, [getIndustriesFullTree]);
	const [showSubCategories, setShowSubCategories] = useState(false);
	const [changeSubCategories, setChangeSubCategories] = useState(false);
	const [subCategory, setSubCategory] = useState([]);
	const [idCategory, setIdCategory] = useState('');

	function toogleSubCategories(nameItem, _id) {
		const category = industriesCategories.filter(industry => industry.key === _id);
		if (nameItem === '') {
			setShowSubCategories(false);
			return;
		}
		if (category.length !== 0) {
			setSubCategory(category[0]);
			setShowSubCategories(true);
			setChangeSubCategories(true);
			setIdCategory(_id);
		} else {
			setSubCategory([]);
			setShowSubCategories(false);
			setChangeSubCategories(false);
			setIdCategory('');
		}
	}
	return (
		<div className="container-sidebar-left container-sidebar-left_border-right">
			<Link to="/">
				<img src={logoWbn} alt="logo_wbn" className="container-sidebar-left__logo" />
			</Link>
			<ul className="container-sidebar-left__list">
				{map(data, ({ name, image, _id }) => {
					return (
						<li key={name} onMouseLeave={() => toogleSubCategories('', _id)}>
							<Link
								to={{ pathname: '/search-result', search: `?business=MA&countries=global&search=${encodeURIComponent(name)}` }}
								onMouseEnter={() => toogleSubCategories(name, _id)}
							>
								<img src={image} alt={name} />
								<span>{name}</span>
							</Link>
						</li>
					);
				})}
				<li key={'allCategories'} className="last-list-sidebar-left">
					<Link to="/industries">
						<span style={{ marginLeft: '32px' }}>All Categories</span>
					</Link>
				</li>

				<CSSTransition in={showSubCategories} classNames="sidebar" unmountOnExit timeout={300}>
					<SubCategories
						showSub={toogleSubCategories}
						changeSubItem={changeSubCategories}
						subCategory={subCategory}
						id={idCategory}
					/>
				</CSSTransition>
			</ul>
		</div>
	);
};

const mapStateToProps = ({ listIndustriesFullTree }) => {
	return {
		industriesCategories: listIndustriesFullTree.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getIndustriesFullTree: () => dispatch(actionWatcher(GET_INDUSTRY_FULL_TREE_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLeft);
