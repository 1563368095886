import React, { useState, useEffect } from 'react';
import { Loader } from 'components';
import useSWR from 'swr';
import { pick, omit } from 'lodash';
import apiClient from 'api-client';
import getPhotoToken from 'utils/get-photo-token';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';
import { Button, InputText, TextArea } from 'components/atoms/';
import { Select } from 'components/molecules/';
import { Checkbox } from 'semantic-ui-react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import coverImgEmpty from 'images/placeholders/cover-business-profile_empty.svg';
import {
	UserProfileEditStyled,
	CoverSection,
	MainInfoUser,
	SecondaryInfoUser,
	Cover,
	CoverOptions,
	SaveChangesTop,
	BriefDescription,
	ProfilePhoto,
	MainForm,
	PhoneWrapper,
	ConfirmationButtons
} from './style';

const parseOptions = (array, ...keys) => {
	return array.map(item => ({
		key: item[keys[0]],
		text: item[keys[1]],
		value: item[keys[2]]
	}));
};
const UserProfileEdit = props => {
	let inputRefProfile, inputRefCover;
	const [isFetching, setIsFetching] = useState(true);
	const [isValidPhone, setIsValidPhone] = useState(false);
	const [isValidDate, setIsValidDate] = useState(true);
	const [countryStates, setCountryState] = useState([]);
	const [userProfilePhoto, setUserProfilePhoto] = useState(props.userProfile.profilePhoto?.url);
	const [userCoverPhoto, setUserCoverPhoto] = useState(props.userProfile.coverPhoto?.url);
	const [buttonState, setButtonState] = useState({ isLoading: false, isDisabled: false });
	const [formValues, setFormValues] = useState({
		...pick(props.userProfile, [
			'firstName',
			'lastName',
			'briefPresentation',
			'city',
			'email',
			'mobilePhone',
			'showBirthDate',
			'showProfession',
			'companyName',
			'industry',
			'profession',
			'birthDate'
		]),
		industry: props.userProfile.industry?.id,
		country: props.userProfile.country.code,
		state: props.userProfile.state.stateID,
		gender: props.userProfile.gender?.code,
		preferredLanguage: props.userProfile.preferredLanguage?.code,
		jobPosition: props.userProfile.jobPosition?.jobPositionID,
		businessType: props.userProfile.businessType?.code
	});
	const { showSuccessToast, showFailureToast } = usePreDefinedToasts();
	const handleImageFileChange = async e => {
		if (e.target.files.length === 0) return;
		let objectURL = URL.createObjectURL(e.target.files[0]);
		let imageFile = e.target.files[0];
		let messageUpload = 'Updating your profile picture',
			messageConfirm = 'Profile picture successfully uploaded';
		try {
			let field = 'profilePhoto';
			if (e.target.id === 'userProfileCover') {
				setUserCoverPhoto(objectURL);
				messageUpload = 'Updating your cover photo';
				messageConfirm = 'Cover photo successfully uploaded';
				field = 'coverPhoto';
			} else {
				setUserProfilePhoto(objectURL);
			}
			showSuccessToast(messageUpload);

			let token = await getPhotoToken(`files/upload-request/user`, imageFile);
			await apiClient.put('/user/profile', {
				[field]: token
			});

			showSuccessToast(messageConfirm);
		} catch (err) {
			showFailureToast('An error has ocurred when we tried to upload your profile picture');
		}
	};
	const saveUserProfileData = async () => {
		setButtonState({ isLoading: true, isDisabled: true });
		if (!isValidPhone) {
			alert('Invalid phone number, please check the format (e.g. + 1 12345678)');
		} else if (!isValidDate) {
			alert('Invalid date format, please check it (e.g. 1970-10-24)');
		} else {
			try {
				let response = await apiClient.put('/user/profile', omit(formValues, ['email']));
				if (response.data?.success) {
					showSuccessToast('Profile data updated successfully.');
					setButtonState({ isLoading: false, isDisabled: false });
					props.history.push("/profile");
				} else {
					showFailureToast('An error has ocurred when trying to update:' + response.message);
					setButtonState({ isLoading: false, isDisabled: false });
				}
			} catch (error) {
				showFailureToast('An error has ocurred when trying to update');
				setButtonState({ isLoading: false, isDisabled: false });
			}
		}
		
	};
	const fetchState = async countryCode => {
		setIsFetching(true);
		const response = await apiClient.get(
			`${process.env.REACT_APP_API_URL}ref-data/country/${countryCode}/state`
		);
		setCountryState(response.data.data);
		setIsFetching(false);
	};
	useEffect(() => {
		fetchState(formValues.country);
	}, [formValues.country]);

	const handleInputText = event => {
		let name = event.target.name;
		let value = event.target.value;
		setFormValues(prev => {
			return {
				...prev,
				[name]: value
			};
		});
		if (name === 'birthDate') {
			const valid = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(value);
			setIsValidDate(valid);
		}
	};
	const handlePhoneInput = (phone, data) => {
		setFormValues(prev => {
			return {
				...prev,
				mobilePhone: {
					countryCode: '+' + data?.dialCode,
					phoneNumber: phone.replace(/[^0-9]+/g, '').slice(data.dialCode ? data.dialCode.length : 0)
				}
			};
		});
	};
	return (
		<UserProfileEditStyled>
			<CoverSection>
				<Cover>
					<img src={userCoverPhoto || coverImgEmpty} alt={'Cover user profile WBNB2B'} />
				</Cover>
				<CoverOptions>
					<SaveChangesTop>
						<Button
							isLoading={buttonState.isLoading}
							disabled={buttonState.isDisabled}
							onClick={saveUserProfileData}
							style={{ width: '100%' }}
						>
							Save Changes
						</Button>
					</SaveChangesTop>

					<div style={{margin: "0 auto"}}>
						<Button
							onClick={() => {
								inputRefCover.click();
							}}
						>
							Update your cover photo
						</Button>
						<input
							ref={input => (inputRefCover = input)}
							type="file"
							id="userProfileCover"
							style={{ display: 'none' }}
							onChange={handleImageFileChange}
							accept="image/*"
						/>
						<p>Maximum 500 KB</p>
					</div>
				</CoverOptions>
			</CoverSection>
			<MainInfoUser>
				<ProfilePhoto>
					<img
						src={
							userProfilePhoto ||
							'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSCqVlrKYSnU7tZsDBcxQt45PUGD7LQa2cfCH1NllRA9hJ4Y0ry'
						}
						alt={'User profile WBNB2B'}
					/>

					<Button
						onClick={() => {
							inputRefProfile.click();
						}}
					>
						Update your profile photo
					</Button>
					<p style={{textAlign: "center"}}>Maximum 500 KB</p>

					<input
						ref={input => (inputRefProfile = input)}
						type="file"
						id="userProfilePhoto"
						style={{ display: 'none' }}
						onChange={handleImageFileChange}
						accept="image/*"
					/>
				</ProfilePhoto>
				<MainForm desktop="500px">
					<MainForm.Row>
						<InputText name="firstName" value={formValues.firstName} onChange={handleInputText} />
						<InputText name="lastName" value={formValues.lastName} onChange={handleInputText} />
					</MainForm.Row>
					<MainForm.Row>
						<Select
							defaultValue={formValues.country}
							options={parseOptions(props.countries, 'code', 'countryName', 'code')}
							onChange={value => {
								setFormValues(prev => ({ ...prev, state: null }));
								handleInputText({ target: { name: 'country', value: value.value } });
							}}
						/>
						{!isFetching ? (
							<Select
								defaultValue={formValues.state}
								options={parseOptions(countryStates, 'stateID', 'stateName', 'stateID')}
								onChange={value => {
									handleInputText({ target: { name: 'state', value: value.value } });
								}}
							/>
						) : null}
					</MainForm.Row>
					<MainForm.Row>
						<InputText name="city" value={formValues.city} onChange={handleInputText} />
						<InputText name="email" value={formValues.email} disabled />
					</MainForm.Row>
					<MainForm.Row>
						<Select
							name="gender"
							defaultValue={formValues.gender}
							options={parseOptions(props.gender, 'code', 'name', 'code')}
							onChange={value => {
								handleInputText({ target: { name: 'gender', value: value.value } });
							}}
						/>
						<Select
							name="preferredLanguage"
							options={parseOptions(props.language, 'code', 'languageName', 'code')}
							defaultValue={formValues.preferredLanguage}
							onChange={value => {
								handleInputText({ target: { name: 'preferredLanguage', value: value.value } });
							}}
						/>
					</MainForm.Row>
					<MainForm.Row>
						<PhoneWrapper>
							<PhoneInput
								country={formValues.mobilePhone?.countryCode || ''}
								isValid={(inputNumber, onlyCountries) => {
									let valid = onlyCountries.some(country => {
										return (
											inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber)
										);
									});
									setIsValidPhone(valid);
									return valid;
								}}
								enableSearch={true}
								value={
									formValues.mobilePhone
										? formValues.mobilePhone.countryCode + formValues.mobilePhone.phoneNumber
										: ''
								}
								enableLongNumbers={true}
								onChange={handlePhoneInput}
							/>
						</PhoneWrapper>
					</MainForm.Row>
				</MainForm>
			</MainInfoUser>
			<SecondaryInfoUser>
				<BriefDescription>
					<p>Brief description about you</p>
					<TextArea
						rows={6}
						name="briefPresentation"
						value={formValues.briefPresentation}
						onChange={handleInputText}
					/>
				</BriefDescription>
				<MainForm>
					<p style={{ textAlign: 'center' }}>Show below information to the public?</p>
					<MainForm.Row>
						<label>Date of birth</label>
						<InputText
							name="birthDate"
							value={formValues.birthDate || ''}
							onChange={handleInputText}
							placeholder={'YYYY-MM-DD'}
						/>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ margin: '5px' }}>No</div>
							<Checkbox
								checked={formValues.showBirthDate}
								toggle
								onChange={(event, data) => {
									setFormValues(prev => ({
										...prev,
										showBirthDate: data.checked
									}));
								}}
							/>
							<div style={{ margin: '5px' }}>Yes</div>
						</div>
					</MainForm.Row>
					<MainForm.Row>
						<label>Profession</label>
						<InputText name="profession" value={formValues.profession || ''} onChange={handleInputText} />
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ margin: '5px' }}>No</div>
							<Checkbox
								checked={formValues.showProfession}
								toggle
								onChange={(event, data) => {
									setFormValues(prev => ({
										...prev,
										showProfession: data.checked
									}));
								}}
							/>
							<div style={{ margin: '5px' }}>Yes</div>
						</div>
					</MainForm.Row>
					<p style={{ textAlign: 'center', marginTop: "10px" }}>YOUR WORK INFO</p>
					<MainForm.Row>
						<label>Type of business</label>
						<Select
							defaultValue={formValues.businessType}
							options={parseOptions(props.businessType, 'code', 'businessTypeName', 'code')}
							onChange={value => {
								handleInputText({ target: { name: 'businessType', value: value.value } });
							}}
						/>
					</MainForm.Row>
					<MainForm.Row>
						<label>Industry</label>
						<Select
							defaultValue={formValues.industry}
							options={parseOptions(props.industry, 'id', 'name', 'id')}
							onChange={value => {
								handleInputText({ target: { name: 'industry', value: value.value } });
							}}
						/>
					</MainForm.Row>
					<MainForm.Row>
						<label>Business Name</label>
						<InputText name="companyName" value={formValues.companyName || ''} onChange={handleInputText} />
					</MainForm.Row>
					<MainForm.Row>
						<label>Job Position</label>
						<Select
							name="jobPosition"
							defaultValue={formValues.jobPosition}
							options={parseOptions(props.jobPosition, 'jobPositionID', 'name', 'jobPositionID')}
							onChange={value => {
								handleInputText({ target: { name: 'jobPosition', value: value.value } });
							}}
						/>
					</MainForm.Row>
				</MainForm>
				<ConfirmationButtons>
					<Button
						onClick={()=>{
							props.history.push("/profile");
						}}
					 flavor="cancel">Cancel</Button>
					<Button
						isLoading={buttonState.isLoading}
						disabled={buttonState.isDisabled}
						flavor="secondary"
						onClick={saveUserProfileData}
					>
						Save Changes
					</Button>
				</ConfirmationButtons>
			</SecondaryInfoUser>
		</UserProfileEditStyled>
	);
};

export default function BusinessEditProfileContainer(props) {
	const { data: profile, error } = useSWR(`user/profile`);
	const { data: country } = useSWR(`ref-data/country`);
	const { data: businessType } = useSWR(`ref-data/business-type`);
	const { data: jobPosition } = useSWR(`ref-data/job-position`);
	const { data: gender } = useSWR(`ref-data/gender`);
	const { data: language } = useSWR(`ref-data/language`);
	const { data: industry } = useSWR(`catsys/industry`);
	const data = [profile, country, businessType, jobPosition, industry, gender, language];
	if (error) {
		return 'An unexpected error has occurred, please try again.';
	}

	if (data.some(e => e === undefined)) {
		return <Loader />;
	}

	return (
		<UserProfileEdit
			userProfile={profile.data?.data}
			countries={country.data?.data}
			businessType={businessType.data?.data}
			jobPosition={jobPosition.data?.data}
			industry={industry.data?.data}
			gender={gender.data?.data}
			language={language.data?.data}
			history={props.history}
		/>
	);
}
