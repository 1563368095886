import React from 'react';
import { SocialSummaryStyle, SocialIcon, SocialCounter } from './style';
import likeIcon from 'images/icons/likes.svg';
const SocialSummaryReactions = ({ reactions }) => {
	return (
		<SocialSummaryStyle>
			<SocialIcon>
				<img src={likeIcon} alt="SocialIcon" />
			</SocialIcon>
			<SocialCounter>{reactions.reactionCount?.like || 0}</SocialCounter>
		</SocialSummaryStyle>
	);
};

export default SocialSummaryReactions;
