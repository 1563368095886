import React from 'react';
import './styles.css';

export default function InlineIcon({ number, text, src, alt, onClick }) {
	return (
		<div className="inline-icon-container">
			<img onClick={onClick} className="inline-circle-icon__shape" src={src} alt={alt} />
			{number || number===0?<span>{number}&nbsp;</span>:<span></span>} <span>{text}</span>
		</div>
	);
}
