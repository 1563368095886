import React from 'react';
import domain_logo from 'domain_logo';

const altImage = `${process.env.REACT_APP_DOMAIN_DEMONYM} Business Guide is a complete list \
	of manufacturing, suppliers, exporters, vendors and professional\
	companies from ${process.env.REACT_APP_DOMAIN_NAME}. We offer direct B2B contact between ${process.env.REACT_APP_DOMAIN_DEMONYM}\
	producers and world industrial distributors... fashion apparel, \
	power transmission, beauty care cosmetics, equipments, food, \
	furniture, engineering, electronics, automation, fashion shoes,\
	tiles, ${process.env.REACT_APP_DOMAIN_DEMONYM} real estate, chemical, men clothing, cosmetics... 
	Your gateway to the ${process.env.REACT_APP_DOMAIN_DEMONYM} manufacturing suppliers`.replace(/\s\s+/g, ' ');
const DomainLogo = props => {
	return <img src={domain_logo} alt={altImage} {...props} />;
};

export default DomainLogo;
