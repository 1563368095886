import React from 'react';
import { map } from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import MembershipBadge from 'components/atoms/membership-badge';
import { Button } from '../../atoms';

import verifiedSvg from 'images/subscriptions-badges/verified.svg';
import verifyBusinessSvg from 'images/placeholders/verify-business.svg';
import businessSvg from 'images/placeholders/business.svg';
import './styles.less';

function isCollaborator(businessSession) {
	const access = businessSession?.access;
	return access === 'owner' || access === 'admin' || access === 'member';
}

function buildBusinessLink({businessID, general: {companyName}}) {
  return encodeURIComponent((companyName + '-' + businessID).replace(/ /g, '-'));
}

export default function SummaryCardBusiness({ businessProfile, businessSession }) {
	const { general: generalInfo, verified: isVerified, subscription: subscriptionName } = businessProfile;
	const collaborator = isCollaborator(businessSession);

	return (
		<div className="container-summary-card-business">
			<div className="container-summary-card-business__content">
				<span>{generalInfo?.businessType?.name}</span>
				<div className="container-summary-card-business__business-picture">
					<Link to={`/business/${buildBusinessLink(businessProfile)}`}>
						<img src={generalInfo?.profilePhoto?.url || businessSvg} alt="business profile" height={160} />
					</Link>
				</div>

				<Link
					to={
						collaborator
							? `/business/${businessProfile.businessID}/requests`
							: `/business/${businessProfile.businessID}/send-request`
					}
				>
					<Button>{collaborator ? 'Requests' : 'Send Request'}</Button>
				</Link>
				<ul>
					<li>Year established: {generalInfo.yearStablished}</li>
					<li>
						Total employees: {generalInfo.totalEmployees?.min} - {generalInfo.totalEmployees?.max}
					</li>
					<li>Industrial categories: {map(generalInfo.categories, 'name').join(', ')}</li>
					<li>Production lead time:</li>
				</ul>
			</div>
			<div className="container-summary-card-business__footer">
				{(isVerified || collaborator) && (
					<img
						className="container-summary-card-business__footer-shape"
						src={isVerified ? verifiedSvg : verifyBusinessSvg}
						alt="It's verified"
					/>
				)}
				<div className="container-summary-card-business__footer-shape">
					<MembershipBadge membershipType={subscriptionName} />
				</div>
			</div>
		</div>
	);
}
