import formatDate from 'date-fns/format';
import getUTCDate from 'utils/get-utc-date';
import apiClient from 'api-client';

const userAvatarFallbackImg =
	'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSCqVlrKYSnU7tZsDBcxQt45PUGD7LQa2cfCH1NllRA9hJ4Y0ry';

const businessAvatarFallbackImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRcRQt2G1UmljCEdBpPBv6nchi6jMaMUOBLXsRlAPDNUwSDJiSe'

export function getSenderName({ senderType, from }) {
	if (senderType === 'business') {
		return from.general.companyName;
	}

	if (senderType === 'user') {
		return `${from.firstName} ${from.lastName}`;
	}

	return null;
}

export function getSenderAvatarUrl({ senderType, from }) {
	if (senderType === 'business') {
		return from.general.profilePhoto?.url || businessAvatarFallbackImg;
	}

	if (senderType === 'user') {
		return from.profilePhoto?.url || userAvatarFallbackImg;
	}
}

export function formatCreatedAt(createdAt) {
	return formatDate(getUTCDate(createdAt), "dd/M/yyyy hh:mm 'UTC'");
}

export function formatMessageDatetime(datetime) {
	return formatDate(getUTCDate(datetime), 'hh:mm');
}

export function checkMessageThread(messageThread, searchTerm) {
	if (!searchTerm) {
		return true;
	}

	const re = new RegExp(searchTerm, 'gmi');

	const { subject, createdAt } = messageThread;
	const senderName = getSenderName(messageThread);
	const formatedDate = formatCreatedAt(createdAt);

	return re.test(senderName) || re.test(subject) || re.test(formatedDate);
}

export async function fetcher(url) {
	const response = await apiClient.get(url);
	return response.data.data;
}

export function toMessageThread(rawMessageThread, businessId) {
	let from = rawMessageThread.from;
	let to = rawMessageThread.to;

	// the current business is the sender
	if (rawMessageThread.from.businessID === businessId) {
		from = rawMessageThread.to;
		to = rawMessageThread.from;
	}

	return {
		...rawMessageThread,
		from,
		to
	};
}
