import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MenuMobile from 'components/menu-mobile';
import MenuDesktop from 'components/menu-desktop';
import apiClient from 'api-client';
import SearchSection from '../search-section';

import DomainLogo from '../domain-components/domain_logo';
import './styles.less';

const Header = ({isSearch = false, history}) => {
	const [showMenu, setShowMenu] = useState(false);
	const [isLogged, setIsLogged] = useState(false);

	useEffect(() => {
		const getProfile = async () => {
			try {
				let response = await apiClient.get(`${process.env.REACT_APP_API_URL}user/profile`);
				localStorage.setItem('userID', response.data.data.userID);
				setIsLogged(true);
			} catch (error) {
				setIsLogged(false);
			}
		};

		getProfile();
	}, []);

	return (
		<>
			<div className="container-header">
				<div className="container-header__left">
					<Icon
						name="sidebar"
						className="container-header__left-icon"
						onClick={() => setShowMenu(!showMenu)}
					/>
					<Link to="/">
						<DomainLogo className="container-header__logo-desktop" />
					</Link>
					<Link to="/">
						<DomainLogo className="container-header__logo-mobile" />
					</Link>
				</div>
				{isSearch?<SearchSection history={history} />:null}
				<MenuDesktop isLogged={isLogged}/>
			</div>

			{showMenu && <MenuMobile setShowMenu={setShowMenu} />}
		</>
	);
};

export default Header;
