import React from 'react';
import { SocialMenuActions, CommentSection, SocialSummaryReactions } from 'components';
import { PostStyled, Author } from './style';
import { format } from 'timeago.js';
import useSWR from 'swr';

const renderName = (author)=>{
	if(author.businessID){
		return author.general.companyName;
	}else{
		return author.firstName + ' ' + author.lastName;
	}
}
const Post = ({ header, body, reactions, user }) => {
	return (
		<PostStyled>
			<PostStyled.Header>
				<Author>
					<Author.Photo>
						<img
							src={
								header.author.profilePhoto?.url ||
								'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSCqVlrKYSnU7tZsDBcxQt45PUGD7LQa2cfCH1NllRA9hJ4Y0ry'
							}
							alt="post"
						/>
					</Author.Photo>
					<Author.Data>
						<Author.Data.Name>{renderName(header.author)}</Author.Data.Name>
						<Author.Data.Time>
							<span title={new Date(header.metadata.datetime).toString()}>
								{format(header.metadata.datetime)}
							</span>
						</Author.Data.Time>
					</Author.Data>
				</Author>
				<div>
					<span role="img" aria-label="Icon options">
						⚙️
					</span>
				</div>
			</PostStyled.Header>
			<PostStyled.Body>
				{body.text ? <PostStyled.Body.Text>{body.text}</PostStyled.Body.Text> : null}
				{body.picture ? (
					<PostStyled.Body.Picture>
						<img
							src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQYy5j5g5gU9l6P4X1tNLQ03Bg3V2wKbL6hMVcp-2rzGybFye_J"
							alt="Business Post WBN"
						/>
					</PostStyled.Body.Picture>
				) : null}
			</PostStyled.Body>
			<PostStyled.Footer>
				<PostStyled.Footer.Summary>
					<SocialSummaryReactions reactions={reactions} />
				</PostStyled.Footer.Summary>

				<PostStyled.Footer.Actions>
					<SocialMenuActions resourceID={header.metadata.resourceID} />
				</PostStyled.Footer.Actions>
			</PostStyled.Footer>
			<CommentSection resourceID={header.metadata.resourceID} user={user} />
		</PostStyled>
	);
};

const PostComponent = ({ header, body, user }) => {
	const { data, error } = useSWR(`social/reaction/summary/${header.metadata.resourceID}`);
	if (error) return <div>Error loading data</div>;
	if (!data) return <></>;
	return <Post header={header} body={body} user={user} reactions={data.data?.data} />;
};

export default PostComponent;
