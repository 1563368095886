import React from 'react';
import territorial_picture from 'territorial_picture';
import territorial_secondary_picture_1 from 'territorial_secondary_picture_1';
import territorial_secondary_picture_2 from 'territorial_secondary_picture_2';

const altImage = `${process.env.REACT_APP_DOMAIN_DEMONYM} Business Guide is a complete list \
	of manufacturing, suppliers, exporters, vendors and professional\
	companies from ${process.env.REACT_APP_DOMAIN_NAME}. We offer direct B2B contact between ${process.env.REACT_APP_DOMAIN_DEMONYM}\
	producers and world industrial distributors... fashion apparel, \
	power transmission, beauty care cosmetics, equipments, food, \
	furniture, engineering, electronics, automation, fashion shoes,\
	tiles, ${process.env.REACT_APP_DOMAIN_DEMONYM} real estate, chemical, men clothing, cosmetics... 
	Your gateway to the ${process.env.REACT_APP_DOMAIN_DEMONYM} manufacturing suppliers`.replace(/\s\s+/g, ' ');

const SmarterAltImage = `Manufacturing, Suppliers, Exporters, Vendors from ${process.env.REACT_APP_DOMAIN_NAME} doing business \
	. WBNB2B Machines, automation`
const DomainLogo = props => {
	if (props.picture === 'MAIN') {
		return <img src={territorial_picture} alt={altImage} {...props} />;
	} else if (props.picture === 'SECONDARY_1') {
		return <img src={territorial_secondary_picture_1} alt={altImage} {...props} />;
	} else {
		return <img src={territorial_secondary_picture_2} alt={SmarterAltImage} {...props} />;
	}
};

export default DomainLogo;
