import React from 'react';
import useSWR, { mutate as mutateSWR } from 'swr';
import { map, findIndex } from 'lodash';
import { Input, Icon, Dropdown } from 'semantic-ui-react';
import { Loader, Autocomplete } from 'components';
import getUTCDate from 'utils/get-utc-date';
import compareDesc from 'date-fns/compareDesc';
import { someHasError, everyIsComplete } from 'utils/requests';
import apiClient from 'api-client';
import {
	getSenderName,
	formatCreatedAt,
	checkMessageThread,
	formatMessageDatetime,
	fetcher,
	toMessageThread,
	getSenderAvatarUrl
} from './helpers';
import {
	LayoutContainer,
	LayoutHeaderContainer,
	LayoutContentContainer,
	MessageThreadsContainer,
	ChatContainer,
	MessageThreadContainer,
	InboxContentContainer,
	InboxFooterContainer,
	InboxHeaderContainer,
	MessageContainer
} from './style';
import usePreDefinedToast from 'hooks/use-pre-defined-toasts';

const MemoizedAutocomplete = React.memo(Autocomplete);

function Layout({ isLoading, children }) {
	return (
		<LayoutContainer>
			<LayoutHeaderContainer>
				<header>Message Center</header>
			</LayoutHeaderContainer>
			{isLoading ? <Loader /> : children}
		</LayoutContainer>
	);
}

function Message({ position, content, datetime, messageThread = {} }) {
	return (
		<MessageContainer position={position}>
			{position === 'left' && (
				<div style={{ marginRight: 10 }}>
					<img
						src={getSenderAvatarUrl(messageThread)}
						alt="avatar"
						height={28}
						width={28}
						style={{ borderRadius: '50%' }}
					/>
				</div>
			)}
			<div>
				<p>{content}</p>
				<p style={{ textAlign: 'right' }}>{formatMessageDatetime(datetime)}</p>
			</div>
		</MessageContainer>
	);
}

function BusinessSelector({ businesses, defaultValue, onSelect }) {
	const businessesOptions = React.useMemo(() => {
		return map(businesses, item => ({
			key: item.businessID,
			text: item.business.general.companyName,
			value: item.businessID
		}));
		// eslint-disable-next-line
	}, []);

	const onBusinessChange = React.useCallback(
		({ value }) => onSelect(value),
		// eslint-disable-next-line
		[]
	);

	return (
		<MemoizedAutocomplete
			style={{ height: 50 }}
			options={businessesOptions}
			defaultValue={defaultValue}
			onChange={onBusinessChange}
		/>
	);
}

function MessageThread({ from, senderType, subject, createdAt, selected, onClick }) {
	return (
		<MessageThreadContainer selected={selected} onClick={onClick}>
			<div>
				<img
					src={getSenderAvatarUrl({ senderType, from })}
					alt="avatar"
					height={50}
					width={50}
					style={{ borderRadius: '50%' }}
				/>
			</div>
			<div>
				<span>{getSenderName({ senderType, from })}</span>
				<span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{subject}</span>
				<span>{formatCreatedAt(createdAt)}</span>
			</div>
		</MessageThreadContainer>
	);
}

function SearchableMessageThreads({ messageThreads, selectedItem, onItemClick }) {
	const [searchTerm, setSearchTerm] = React.useState('');

	return (
		<>
			<div style={{ padding: '10px 20px' }}>
				<Input
					value={searchTerm}
					autoComplete="off"
					spellCheck={false}
					onChange={e => setSearchTerm(e.target.value)}
					icon="search"
					style={{ width: '100%' }}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
				{Array.isArray(messageThreads) &&
					messageThreads
						.sort((a, b) => compareDesc(getUTCDate(a.createdAt), getUTCDate(b.createdAt)))
						.filter(item => checkMessageThread(item, searchTerm))
						.map(item => (
							<MessageThread
								selected={item.requestID === selectedItem?.requestID}
								key={item.requestID}
								onClick={() => onItemClick(item.requestID)}
								{...item}
							/>
						))}
			</div>
		</>
	);
}

function ChatInput({ messageThread, businessId, addMessage, disabled }) {
	const [text, setText] = React.useState('');
	const isPostingRef = React.useRef(false);

	async function sendText() {
		if (isPostingRef.current || !text) {
			return;
		}

		try {
			isPostingRef.current = true;

			addMessage(text);
			setText('');
			await apiClient.post(`business/request/${messageThread.requestID}/send-message?bid=${businessId}`, {
				content: text
			});
		} catch (error) {
			console.error(error);
		} finally {
			isPostingRef.current = false;
		}
	}

	return (
		<InboxFooterContainer>
			<Input
				disabled={disabled}
				value={text}
				onKeyPress={e => e.key === 'Enter' && sendText()}
				onChange={e => setText(e.target.value)}
				placeholder={disabled ? 'This chat has ended' : 'Type your response here'}
			/>
			<Icon disabled={disabled} name="paper plane" size="big" onClick={sendText} />
		</InboxFooterContainer>
	);
}

function Chat({
	rawMessageThread,
	messageThread,
	revalidateMessageThreads,
	businessId,
	onBackClick,
	onViewBuyerProfileClick
}) {
	const [processing, setProcessing] = React.useState(false);
	const { showSuccessToast, showFailureToast } = usePreDefinedToast();

	const viesAsSender = businessId !== rawMessageThread?.to?.businessID;
	const disabled = messageThread?.status === 'closed';

	const messagesContainerRef = React.useRef();

	const { data: messages, error } = useSWR(
		messageThread ? `business/request/${messageThread.requestID}/get-messages?bid=${businessId}` : null,
		fetcher
	);

	function scrollToBottom() {
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
		}
	}

	React.useEffect(() => {
		if (messageThread?.requestID) {
			scrollToBottom();
		}
	}, [messageThread, messages]);

	async function endConversation() {
		try {
			setProcessing(true);
			await apiClient.post(`business/request/${messageThread.requestID}/end-request?bid=${businessId}`);
			await revalidateMessageThreads();
			showSuccessToast('Finished successfully');
		} catch (error) {
			showFailureToast();
		} finally {
			setProcessing(false);
		}
	}

	if (!messageThread) {
		return <ChatContainer />;
	}

	if (error) {
		return <ChatContainer>An unexpected error has occurred, please try again.</ChatContainer>;
	}

	if (!messages) {
		return (
			<ChatContainer style={{ justifyContent: 'center' }}>
				<Loader />
			</ChatContainer>
		);
	}

	return (
		<ChatContainer>
			<InboxHeaderContainer>
				<div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
					<span id="back" onClick={onBackClick}>
						<Icon name="angle left" />
					</span>
					<span>Inbox Reply</span>
					<div style={{ marginLeft: 'auto' }}>
						<Dropdown icon={{ name: 'setting', size: 'big' }} direction="left" loading={processing}>
							<Dropdown.Menu>
								<Dropdown.Item disabled={disabled} text="End Conversation" onClick={endConversation} />
								{!viesAsSender && messageThread?.senderType === 'business' && (
									<Dropdown.Item
										text="Watch Buyer Profile"
										onClick={() => {
											onViewBuyerProfileClick(messageThread.from.businessID);
										}}
									/>
								)}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ flex: 1, marginRight: 20 }}>
						{getSenderName(messageThread)}
						<br />
						{messageThread?.subject}
					</div>
					<div>
						First message sent at
						<br />
						{formatCreatedAt(messageThread.createdAt)}
					</div>
				</div>
			</InboxHeaderContainer>
			<InboxContentContainer ref={messagesContainerRef}>
				{map(messages, item => {
					let position;

					if (viesAsSender) {
						position = item.from === 'S' ? 'right' : 'left';
					} else {
						position = item.from === 'S' ? 'left' : 'right';
					}

					return <Message key={item.datetime} messageThread={messageThread} position={position} {...item} />;
				})}
			</InboxContentContainer>
			<ChatInput
				disabled={disabled}
				messageThread={messageThread}
				businessId={businessId}
				addMessage={content => {
					const message = {
						content,
						datetime: new Date().toUTCString(),
						from: viesAsSender ? 'S' : 'R',
						type: 'text'
					};

					const newData = [...messages, message];

					mutateSWR(
						`business/request/${messageThread.requestID}/get-messages?bid=${businessId}`,
						newData,
						false
					);

					setTimeout(scrollToBottom, 0);
				}}
			/>
		</ChatContainer>
	);
}

function BusinessRequestsPage({
	businesses,
	rawMessageThreads,
	revalidateMessageThreads,
	businessId,
	threadId,
	history
}) {
	const messageThreads = React.useMemo(() => {
		return map(rawMessageThreads, item => toMessageThread(item, businessId));
	}, [businessId, rawMessageThreads]);

	const { selectedMessageThread, selectedRawMessageThread } = React.useMemo(() => {
		const index = findIndex(messageThreads, { requestID: threadId });

		return {
			selectedMessageThread: messageThreads[index],
			selectedRawMessageThread: rawMessageThreads[index]
		};
	}, [messageThreads, rawMessageThreads, threadId]);

	return (
		<Layout>
			<LayoutContentContainer focusOnChat={!!threadId}>
				<MessageThreadsContainer>
					<BusinessSelector
						businesses={businesses}
						defaultValue={businessId}
						onSelect={bid => history.push(`/business/${bid}/requests`)}
					/>
					<SearchableMessageThreads
						businessId={businessId}
						messageThreads={messageThreads}
						selectedItem={selectedMessageThread}
						onItemClick={rid => {
							history.push(`/business/${businessId}/requests/${rid}`);
						}}
					/>
				</MessageThreadsContainer>
				<Chat
					businessId={businessId}
					revalidateMessageThreads={revalidateMessageThreads}
					rawMessageThread={selectedRawMessageThread}
					messageThread={selectedMessageThread}
					onBackClick={() => {
						history.push(`/business/${businessId}/requests`);
					}}
					onViewBuyerProfileClick={buyerProfileId => {
						history.push(`/business/${buyerProfileId}`);
					}}
				/>
			</LayoutContentContainer>
		</Layout>
	);
}

export default function BusinessRequestsContainer({ match, history }) {
	const businessId = match.params.id;
	const threadId = match.params.rid;

	const businesses = useSWR('business/profile/my-list');
	const messageThread = useSWR(`business/request/by-business?bid=${businessId}`);

	const responses = [messageThread, businesses];

	if (someHasError(responses)) {
		return 'An unexpected error has occurred, please try again.';
	}

	if (!everyIsComplete(responses)) {
		return <Layout isLoading />;
	}

	return (
		<BusinessRequestsPage
			history={history}
			businessId={businessId}
			threadId={threadId}
			revalidateMessageThreads={messageThread.revalidate}
			rawMessageThreads={messageThread.data.data.data}
			businesses={businesses.data.data.data}
		/>
	);
}
