import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'components/atoms/button';
import { ContentCardComments, OwnLoader } from 'components';
import './styles.less';
import apiClient from 'api-client';
import img_empty from 'images/img_profile_empty.svg';
import coverImgEmpty from 'images/placeholders/cover-business-profile_empty.svg';
import create_business from '../../images/create_your_business_profile.svg';

import { GET_LANGUAGES_WATCHER } from 'store/languages/constants';
import { GET_LIST_MY_BUSINESS_WATCHER } from 'store/list-my-business/constants';

import { actionWatcher } from 'shared/actions';

const Profile = ({ myBusiness, getLanguages, getListMyBusiness, history }) => {
	const [info, setInfo] = useState({});
	window.scrollTo(0, 0);
	const getProfile = async () => {
		try {
			let { data } = await apiClient.get(`${process.env.REACT_APP_API_URL}user/profile`);
			if (data) {
				setInfo(data.data);
			} else {
				setInfo({});
			}
		} catch (error) {
			console.error(error);
			setInfo({});
			window.location.replace('/');
		}
	};

	useEffect(() => {
		getLanguages();
		getProfile();
		getListMyBusiness();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!info.userID) return <OwnLoader text="Loading your user profile..." />;
	return (
		<div className="container-profile-wrapper">
			<div className="container-profile">
				<div className="container-profile__cover-photo">
					<img src={info.coverPhoto?.url || coverImgEmpty} alt="profile" />
				</div>
				<div className="container-profile__head-info">
					<div className="container-profile__head-info-photo">
						<img src={info.profilePhoto?.url || img_empty} alt="profile" />
					</div>
					<div className="container-profile__head-info-section">
						<div className="container-profile__head-info-left-section">
							<p>
								{info.firstName} {info.lastName}
							</p>
							<p>{info.profession || ''}</p>
							<p>
								{(info.country && info.country.countryName) || ''}/
								{(info.state && info.state.stateName) || ''}
							</p>
						</div>
						<div className="container-profile__head-info-right-section">
							<p>{info.businessType ? `Type of Business: ${info.businessType.name}` : null}</p>
							<p>{info.birthDate ? `Date of Birth: ${info.birthDate}` : null}</p>
						</div>
						<div className="container-profile__head-info-actions">
							<Button
								onClick={() => {
									history.push('/profile/edit');
								}}
							>
								Edit profile
							</Button>
						</div>
					</div>
				</div>

				<div className="container-profile__detail-info">
					<p>About me:</p>
					<p>{info.briefPresentation}</p>
					{info.companyName ? <p>Work Company Name: {info.companyName}</p> : ''}
					{info.businessType ? <p>Business Type: {info.businessType.name}</p> : ''}
					{info.industry ? <p>Type of Industry: {info.industry.name}</p> : ''}
					{info.jobPosition ? <p>Job Position: {info.jobPosition.name}</p> : ''}
					{info.preferredLanguage ? <p>Preferred language: {info.preferredLanguage.name}</p> : ''}
				</div>
				<div className="container-profile__message">
					<div className="container-profile__message-cont">
						<Link to="business/create">
							<Button style={{ margin: '0 auto' }}>CREATE YOUR BUSINESS PROFILE NOW</Button>
						</Link>
					</div>
				</div>
				<div className="container-profile__business">
					{myBusiness.loading ? (
						<OwnLoader text="Loading your business..." />
					) : (
						myBusiness.data.map((item, key) => {
							return <ContentCardComments business={item.business} key={key} history={history} />;
						})
					)}
				</div>
				<div className="container-profile__invite">
					<div className="container-profile__invite-img">
						<div className="container-profile__invite-img--left">
							<Link to="/business/create">
								<img src={create_business} alt="create business" />
							</Link>
						</div>
						<div className="container-profile__invite-img--right">
							<img src={`/img/domain/${process.env.REACT_APP_DOMAIN_NAME}/DABIE/DABIE_8_${process.env.REACT_APP_DOMAIN_NAME}.svg`} alt="create business dabie" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({
	countries,
	countryState,
	gender,
	languages,
	jobPosition,
	listBusiness,
	listMyBusiness
}) => {
	return {
		countries: countries.countries,
		countryState: countryState.data,
		gender: gender.data,
		languages: languages.data,
		jobPosition: jobPosition.data,
		listBusiness: listBusiness.data,
		myBusiness: listMyBusiness
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLanguages: () => dispatch(actionWatcher(GET_LANGUAGES_WATCHER)),
		getListMyBusiness: () => dispatch(actionWatcher(GET_LIST_MY_BUSINESS_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
