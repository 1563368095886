import React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import logoWbn from 'domain_brand';
import { Icon } from 'semantic-ui-react';
import './styles.less';

const accountList = [
	{ name: 'My account', icon: 'user', path: '/profile' },
	{ name: 'Messages', icon: 'chat', path: '/my-messages' },
	{ name: 'My business', icon: 'suitcase', path: '/my-business' }
];

const mainMenuList = [
	{ name: 'Home', icon: 'home', path: '/' },
	{ name: 'Articles', icon: 'file alternate outline', path: '/articles' },
	{ name: 'Industries', icon: 'industry', path: '/industries' }
];

const MenuMobile = ({ setShowMenu }) => {
	return (
		<div className="container-menu-mobile">
			<div className="container-menu-mobile__content">
				<div className="container-menu-mobile__header">
					<div>
						<img className="container-menu-mobile__logo" src={logoWbn} alt="WBNB2B LOGO Industrial B2B2 Manufacturers" />
					</div>
					<Icon
						name="close"
						className="container-menu-mobile__header-close"
						onClick={() => setShowMenu(false)}
					/>
				</div>
				<div className="container-menu-mobile__item">
					<div className="container-menu-mobile__item__title">ACCOUNT</div>
					<ul className="container-menu-mobile__item__list">
						{map(accountList, ({ name, icon, path }) => {
							return (
								<li key={name}>
									<Link to={path} onClick={() => setShowMenu(false)}>
										<Icon name={icon} />
										{name}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>

				<div className="container-menu-mobile__item">
					<div className="container-menu-mobile__item__title">MAIN MENU</div>
					<ul className="container-menu-mobile__item__list">
						{map(mainMenuList, ({ name, icon, path }) => {
							return (
								<li key={name}>
									<Link to={path} onClick={() => setShowMenu(false)}>
										<Icon name={icon} />
										{name}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>

				<div className="container-menu-mobile__item">
					<div className="container-menu-mobile__item__title">SUPPORT</div>
					<ul className="container-menu-mobile__item__list">
						<li>
							<Link to="/">
								<Icon name="phone" />
								Contact us
							</Link>
						</li>
					</ul>
				</div>

				<div className="container-menu-mobile__item">
					<div className="container-menu-mobile__item__title" />
					<ul className="container-menu-mobile__item__list">
						<li>
							<Link to="/">
								<Icon name="log out" />
								Logout
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<div className="container-menu-mobile-mask" onClick={() => setShowMenu(false)} />
		</div>
	);
};

export default MenuMobile;
