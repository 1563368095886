import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Button } from 'components/atoms';
import { connect } from 'react-redux';
import {
	iconMessage,
	iconNotification,
	iconContacts,
	iconHome,
	iconMyBusiness,
	iconProfile
} from './icons_header';
import { POST_LOGOUT_WATCHER } from 'store/logout/constants';
import { actionWatcher } from 'shared/actions';

const MenuDekstop = ({ isLogged, postLogout }) => {
	return (
		<div className="container-header__actions">
			{isLogged ? (
				<>
					<Link to="/" className="container-header__actions-option">
						<div className="container-header__actions-option-container">
							<img src={iconHome} alt="home" />
							<span>Home</span>
						</div>
					</Link>
					<Link to={`/my-business`} className="container-header__actions-option">
						<div className="container-header__actions-option-container">
							<img src={iconMyBusiness} alt="My Business" />
							<span>My Business</span>
						</div>
					</Link>
					<Link to="/newsfeed" className="container-header__actions-option">
						<div className="container-header__actions-option-container">
							<img src={iconContacts} alt="B2B" />
							<span>My B2B</span>
						</div>
					</Link>

					<Link to={`/`} className="container-header__actions-option">
						<div className="container-header__actions-option-container">
							<img src={iconMessage} alt="Messaging" />
							<span>Messages</span>
						</div>
					</Link>
					<Link to={`/`} className="container-header__actions-option">
						<div className="container-header__actions-option-container">
							<img src={iconNotification} alt="help" />
							<span>Notifications</span>
						</div>
					</Link>
					<div className="container-header__actions-option-container container-header__actions-option-container_user">
						<img src={iconProfile} alt="" />
						<Dropdown icon="caret down" pointing className="container-header__actions-user icon right">
							<Dropdown.Menu>
								<Dropdown.Header className="container-header__actions-user-header " content="Account" />
								<Dropdown.Item as={Link} to="/profile" text="My profile" />
								<Dropdown.Item text="Settings" />
								<Dropdown.Item text="Language" />

								<Dropdown.Header content="" className="container-header__actions-user-header" />
								<Dropdown.Item text="Favorite Business" />
								<Dropdown.Header content="" className="container-header__actions-user-header" />

								<Dropdown.Item as={Link} to="/contact-us/form" text="Need help?" />
								<Dropdown.Item as={Link} to="/contact-us/form" text="Report something" />
								<Dropdown.Header content="" className="container-header__actions-user-header" />
								<Dropdown.Item text="Logout" onClick={() => postLogout()} />
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</>
			) : (
				<>
					<Button
						style={{"marginLeft": "5px"}}
						onClick={() =>
							window.open(
								`${process.env.REACT_APP_AUTH_URL}serviceLogin?continue=${encodeURIComponent(window.location)}`,
								'_self'
							)
						}
					>
						Login
					</Button>
					<Button
						onClick={() =>
							window.open(`${process.env.REACT_APP_ACCOUNT_URL}register?continue=${encodeURIComponent(window.location)}`, '_self')
						}
					>
						Register
					</Button>
				</>
			)}
		</div>
	);
};

const mapStateToProps = ({ logout }) => {
	return {
		logout: logout.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		postLogout: () => dispatch(actionWatcher(POST_LOGOUT_WATCHER))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDekstop);
