const Auth = {
	redirectToLogin: (path) => {
		let redirectURL = `${process.env.REACT_APP_AUTH_URL}ServiceLogin?continue=${path?path:window.location.href}`;
		window.open(redirectURL, '_self');
	},
	checkRefreshTokenValid: () => {
		let refreshTokenExpire = localStorage.getItem('refreshTokenExpire');
		return !(refreshTokenExpire == null || refreshTokenExpire < Number(new Date()));
	},
	hasValidSession: ()=>{
		return localStorage.getItem('token') && localStorage.getItem('refreshToken') && Auth.checkRefreshTokenValid();
	}
};

export default Auth;
