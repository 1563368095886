import React from 'react';
import styled from 'styled-components';

const PostCommentStyle = styled.div`
	display: flex;
	margin: 10px 0px;
`;
PostCommentStyle.UserPicture = styled.div`
	> img {
		width: 35px;
		height: 35px;
		object-fit: contain;
		border-radius: 50%;
		border: 1px solid #e3e3e3;
	}
`;
PostCommentStyle.Data = styled.div`
	margin: 0px 5px;
`;
PostCommentStyle.Author = styled.div`
	font-size: 13px;
`;
PostCommentStyle.CommentBody = styled.div`
	font-size: 12px;
`;
PostCommentStyle.Footer = styled.div`
	display: flex;
	font-size: 11px;
	> div {
		margin-right: 5px;
		text-decoration: underline;
		cursor: pointer;
		color: #333;
	}
`;
const PostComment = ({ user, body }) => {
	return (
		<PostCommentStyle>
			<PostCommentStyle.UserPicture>
				<img
					src={user.profilePhoto?.url || "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSCqVlrKYSnU7tZsDBcxQt45PUGD7LQa2cfCH1NllRA9hJ4Y0ry"}
					alt="user who comment post"
				/>
			</PostCommentStyle.UserPicture>
			<PostCommentStyle.Data>
				<PostCommentStyle.Author>{user.firstName + ' ' + user.lastName}</PostCommentStyle.Author>
				<PostCommentStyle.CommentBody>{body}</PostCommentStyle.CommentBody>
				<PostCommentStyle.Footer>
					<div>Like</div>
					<div>Reply</div>
				</PostCommentStyle.Footer>
			</PostCommentStyle.Data>
		</PostCommentStyle>
	);
};

export default PostComment;
