import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import userProfilePlaceholder from 'images/img_profile_empty.svg';
import { OwnLoader } from 'components';
import axios from 'axios';
import './styles.less';

const ProfilePublic = props => {
	const [info, setInfo] = useState({});
	const {
		match: { params }
	} = props;

	useEffect(() => {
		const getInfo = async () => {
			try {
				const {
					data: { success, profile }
				} = await axios.request({
					method: 'GET',
					url: `${process.env.REACT_APP_API_URL}user/public-profile/${params.id}`
				});

				if (!success) {
					return window.location.replace('/');
				}

				setInfo(profile);
			} catch (error) {}
		};

		getInfo();
	}, [params.id]);

	if (!info.userID) return <OwnLoader />;
	return (
		<div className="container-profile-public">
			{info.profilePhoto ? (
				<img src={info.profilePhoto?.url || userProfilePlaceholder } alt="imageuploaded" className="image-uploaded" />
			) : (
				<Icon name="user circle" size="massive" className="outline" />
			)}
			<h2>{`${info.firstName || 'Loading...'} ${info.lastName || ''}`}</h2>
			<div className="container-profile__place">
				<span>{info.country.countryName || 'unknown'}</span>
				{' - '}
				<span>{info.state ? info.state.stateName : ''}</span>
				{' - '}
				<span>{info.city}</span>
			</div>
			<div className="container-profile__description">
				{info.briefPresentation ? info.briefPresentation : 'Generic short description about me'}
			</div>

			<div className="container-profile__info">
				<div className="container-profile__info-title">Business Info</div>
				<div className="container-profile__info-body">
					<div className="container-profile__info-item">
						<span>Industry: </span>
						<span>{info.businessType?.name}</span>
					</div>
					<div className="container-profile__info-item">
						<span>Business name: </span>
						<span>{info.companyName || ''}</span>
					</div>
					<div className="container-profile__info-item">
						<span>Job position: </span>
						<span>{info.jobPosition ? info.jobPosition.name : ''}</span>
					</div>
				</div>
			</div>

			<div className="container-profile__info-business">
				<div className="container-profile__info-title">Business Info</div>
				<div className="container-profile__info-items">

				</div>
			</div>
		</div>
	);
};

export default ProfilePublic;
