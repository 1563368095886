import React from 'react';
import { truncate } from 'lodash';
import './styles.css';

export default function ViewMoreText({ children: text, maxPreviewChars }) {
	const [isViewMoreActived, setViewMore] = React.useState(false);
	const truncatedText = truncate(text, { length: maxPreviewChars });

	return (
		<p className="view-more-text">
			{isViewMoreActived ? text : truncatedText}
			{` `}
			{text?.length > truncatedText?.length && (
				<span className="view-more-text__trigger" onClick={() => setViewMore(s => !s)}>
					{isViewMoreActived ? 'View less' : 'View more'}
				</span>
			)}
		</p>
	);
}
