import React from 'react';
import { Loader } from 'components/atoms';
import './styles.css';

export default function Button({ flavor = 'primary', children, isLoading = false, ...props }) {
	return (
		<button className={`wbn-button wbn-button-${flavor}`} {...props}>
			{isLoading ? <Loader /> : children}
		</button>
	);
}
