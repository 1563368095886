import styled from 'styled-components';
import breakpoints from 'breakpoints';

export const ProductStyle = styled.div`
	display: flex;
`;

ProductStyle.MainInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${breakpoints('lg')`
		width: 690px;
	`}
`;
ProductStyle.OtherInformation = styled.div`
	display: none;
	${breakpoints('lg')`
		display:flex;
	`}
`;
ProductStyle.ShortDescription = styled.div`
	margin: 0px 10px;
	> div:nth-child(1) {
		text-align: center;
	}
	> div:nth-child(2) {
		margin: 10px 0px;
		font-size: 12px;
		text-align: justify;
	}
`;
ProductStyle.ProductHead = styled.div`
	display: flex;
	margin: 20px 0px;
	flex-direction: column;
	${breakpoints('sm')`
		flex-direction: row;
	`}
	> div:nth-child(2) {
		display: flex;
		margin: 20px auto;
		${breakpoints('lg')`
			display:none;
		`}
	}
`;
ProductStyle.ProductPictureWrapper = styled.div`
	width: 100%;
`;
ProductStyle.ProductPicture = styled.div`
	margin: 0 auto;
	max-width: 445px;
	padding: 22px 22px 5px 22px;
	border-radius: 15px;
	background-color: var(--wbn-primary-color_navbar);
`;
ProductStyle.SummaryCardWrapper = styled.div`
	display: none;
	max-width: 236px;
	${breakpoints('sm')`
		display: block;
	`}
`;
ProductStyle.ProductPicture.Head = styled.div`
	border-radius: 15px;
	margin: 0 auto;
	> img {
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-color: white;
	}
`;
ProductStyle.ProductPicture.Summary = styled.div`
	height: 20px;
	padding: 2px 2px;
`;
ProductStyle.ProductPicture.Actions = styled.div`
	border-top-style: solid;
	border-top-color: #a3a3a3;
`;

ProductStyle.TechnicalSpecs = styled.div`
	margin: 10px 10px;
`;
ProductStyle.TechnicalSpecs.List = styled.div`
	margin: 10px 0px;
	border-radius: 15px;
	padding: 15px;
	width: 100%;
	background-color: var(--wbn-primary-color_navbar);
	> span {
		text-decoration: underline;
	}
`;

export const TechnicalSpecs = styled.div`
	margin: 10px 0px;
	font-size: 12px;
`;
TechnicalSpecs.Row = styled.div`
	> div {
		margin-right: 5px;
	}
`;
ProductStyle.Multimedia = styled.div``;
ProductStyle.CommentSection = styled.div`
	border-radius: 15px;
	margin: 20px 5px;
	background-color: white;
`;
export const SecondaryPictures = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 20px 10px;
	width: 100%;
	justify-items: center;
	align-items: center;
`;
SecondaryPictures.Wrapper = styled.div`
	width: 200px;
	height: 200px;

	> img {
		background-color: white;
		border-radius: 15px;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

export const ProductVideo = styled.div`
	margin: 20px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
ProductVideo.Content = styled.div`
	margin: 15px 0px;
	video{
		width: 100%;
		${breakpoints('sm')`
			max-width: 400px;
		`}
	}
`
