import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Button, TextArea, SocialPost, Loader } from 'components';
import ChooseActor from './choose-actor';
import { CreatePostStyle } from './style';
import auth from 'auth';
import Provider from './provider';
import Context from './context';
import usePreDefinedToasts from 'hooks/use-pre-defined-toasts';
import apiClient from 'api-client';

const createPost = async ({ text, timelineID, timelineType = 'user', businessID }) => {
	try {
		let response = await apiClient.post('/social/posts/make-post', {
			content: text,
			timelineID,
			timelineType,
			businessID
		});
		return response.data;
	} catch (err) {
		throw err.reponse;
	}
};
const getPost = async ({ postID }) => {
	try {
		let response = await apiClient.get('/social/posts/' + postID);
		return response.data;
	} catch (error) {
		return error.response;
	}
};
const CreatePostComponent = ({ user, setRecentPosts, myBusiness, socialActor }) => {
	const [content, setContent] = useState({
		text: '',
		timelineID: socialActor,
		uploading: false,
		timelineType: 'user',
		businessID: null
	});
	const { showSuccessToast, showFailureToast } = usePreDefinedToasts();
	const [profilePhoto, setProfilePhoto] = useState(user.profilePhoto?.url);

	useEffect(() => {
		if (socialActor !== user.userID) {
			setProfilePhoto(myBusiness.find(e => e.businessID === socialActor).business.general.profilePhoto?.url);
			setContent(prev => ({
				...prev,
				timelineID: socialActor,
				timelineType: 'business',
				businessID: socialActor
			}));
		} else {
			setContent(prev => ({ ...prev, timelineID: socialActor, timelineType: 'user', businessID: undefined }));
			setProfilePhoto(user.profilePhoto?.url);
		}
	}, [socialActor, myBusiness, user]);

	const callCreatePost = async () => {
		try {
			setContent(prev => ({ ...prev, uploading: true }));
			let response = await createPost(content);
			showSuccessToast('Post created!');
			setContent(prev => ({ ...prev, uploading: false, text: '' }));
			response = await getPost({ postID: response.data.postID });
			setRecentPosts(prev => [response.data, ...prev]);
		} catch (err) {
			setContent(prev => ({ ...prev, uploading: false }));
			showFailureToast('There was an error creating this post!');
		}
	};

	return (
		<CreatePostStyle>
			<CreatePostStyle.Header>
				<div>Create Post</div>
				<div>
					<ChooseActor user={user} myBusiness={myBusiness} />
				</div>
			</CreatePostStyle.Header>
			<CreatePostStyle.Body>
				<CreatePostStyle.Body.Photo>
					<img
						src={
							profilePhoto ||
							'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSCqVlrKYSnU7tZsDBcxQt45PUGD7LQa2cfCH1NllRA9hJ4Y0ry'
						}
						alt="post"
					/>
				</CreatePostStyle.Body.Photo>
				<CreatePostStyle.Body.Content>
					<TextArea
						rows={3}
						placeholder="Create your post"
						showCounter={false}
						value={content.text}
						onChange={e => {
							let value = e.target.value;
							setContent(prev => ({ ...prev, text: value }));
						}}
					/>
				</CreatePostStyle.Body.Content>
			</CreatePostStyle.Body>
			<CreatePostStyle.Footer>
				<div>{/*Photo/Video*/}</div>
				<div>
					<Button
						disabled={!content.text || content.uploading}
						onClick={() => {
							callCreatePost();
						}}
					>
						Post
					</Button>
				</div>
			</CreatePostStyle.Footer>
		</CreatePostStyle>
	);
};
const RenderPosts = React.memo(({posts, user}) => {
	return posts.map((post, key) => {
		return (
			<SocialPost
				key={key}
				body={{ text: post.content }}
				header={{
					metadata: { resourceID: post.postID, datetime: Date.parse(post.createdAt) },
					author: post.author
				}}
				user={user}
			/>
		);
	});
});
const NewsFeed = ({ posts, recentPosts, user }) => {
	return (
		<div>
			<RenderPosts posts={recentPosts} user={user}/>
			<RenderPosts posts={posts} user={user}/>
		</div>
	);
};
const NewsFeedComponent = () => {
	useEffect(() => {
		if (!auth.hasValidSession()) {
			auth.redirectToLogin();
		}
	}, []);
	const { data: newsfeed, error: errorNewsfeed } = useSWR('newsfeed/load');
	const { data: myBusiness, error: errorMyBusiness } = useSWR('business/profile/my-list');
	const { data: user, error: errorUser } = useSWR('user/profile');
	const [recentPosts, setRecentPosts] = useState([]);
	if (errorNewsfeed || errorUser || errorMyBusiness) return <div>Failed loading your newsfeed</div>;
	if (!newsfeed || !user || !myBusiness) return <Loader text="Loading your newsfeed..." />;

	return (
		<Provider>
			<Context.Consumer>
				{({ socialActor }) => (
					<div>
						<CreatePostComponent
							user={user.data?.data}
							setRecentPosts={setRecentPosts}
							myBusiness={myBusiness.data?.data}
							socialActor={socialActor}
						/>
						<NewsFeed
							recentPosts={recentPosts}
							posts={newsfeed.data?.data?.items}
							user={user.data?.data}
							socialActor={socialActor}
						/>
					</div>
				)}
			</Context.Consumer>
		</Provider>
	);
};

export default NewsFeedComponent;
