import apiClient from 'api-client';

export const reactToEntity = async ({ resourceType, resourceID, businessID, reactionType }) => {
	try {
		let response = await apiClient.post(`/social/reaction/react/${resourceType}`, {
			resourceID,
			businessID,
			reactionType
		});
		return response.data;
	} catch (error) {
		throw error.data;
	}
};

export const deleteReactionFromResource = async ({ resourceType, resourceID, businessID }) => {
	try {
		let response = await apiClient.delete(`/social/reaction/react/${resourceType}`, {
			data: {
				resourceID,
				businessID
			}
		});
		return response.data;
	} catch (error) {
		return error.data;
	}
};

export const postCommentOnEntity = async ({ resourceID, resourceType = 'post', content, businessID }) => {
	try {
		let response = await apiClient.post('/social/comments/make-comment', {
			resourceID,
			resourceType,
			content,
			businessID
		});
		return response.data;
	} catch (error) {
		return error.data;
	}
};
export const reactToPost = async ({ resourceID, businessID, reactionType = 'like' }) => {
	let resourceType = 'post';
	try {
		let payload = { resourceType, resourceID, reactionType };
		if (businessID) payload.businessID = businessID;
		let response = await reactToEntity(payload);
		return response;
	} catch (error) {
		return { success: false, error: error };
	}
};

export const deleteReactionFromPost = async ({ resourceID, businessID }) => {
	console.log('DELETE REACTION FROM POST', resourceID);
	try {
		let response = deleteReactionFromResource({ resourceType: 'post', resourceID, businessID });
		return response;
	} catch (error) {
		return error;
	}
};

export const reactSummary = async ({ resourceID }) => {
	try {
	} catch (error) {
		return { success: false, error: error };
	}
};

export const checkReaction = async ({ resourceID, businessID }) => {
	try {
		let response = await apiClient.get(
			`/social/reaction/check/${resourceID}${businessID ? '?businessID=' + businessID : ''}`
		);
		return response.data;
	} catch (error) {
		return error.data;
	}
};
